import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetDeviceQuery, useCreateDeviceMutation, useUpdateDeviceMutation } from '@app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { IDeviceRead, IDeviceCreate, IDeviceUpdate } from './deviceTypes';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react'
import TagSelect from '@features/tags/TagSelect';

const DEFAULT_CREATE_VALUES: IDeviceCreate = {
    name: "",
    // organization_id: "",
    tag_ids: [],
}

const cleanReadDataForUpdate = (device: IDeviceRead): IDeviceUpdate => {
    const { tags, created_at, updated_at, ...rest } = device;
    return { tag_ids: tags.map(tag => tag.id), ...rest };
}

interface IDeviceFormDialogProps {
    deviceId?: IDeviceRead["id"];
    render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing devices.
 * It can be opened in two modes:
 * 1. Create mode: deviceId is not given
 * 2. Edit mode: deviceId is given
 *
 * In edit mode, the device data is fetched from the API so
 * that the form can be pre-filled with the existing device data.
 */
const DeviceFormDialog: React.FC<IDeviceFormDialogProps> = ({ deviceId, render }) => {
    const [open, setOpen] = useState(false);

    const createMode: boolean = !deviceId;

    const form = useForm<IDeviceCreate | IDeviceUpdate>();

    // Delay data fetching until deviceId is defined and dialog is opened
    const { data: device, isLoading: isDataLoading } = useGetDeviceQuery(deviceId ?? skipToken, { skip: !open });

    const [triggerCreate, {
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        isError: isCreateError

    }] = useCreateDeviceMutation();
    const [triggerUpdate, {
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError
    }] = useUpdateDeviceMutation();

    const isLoading = (isCreateLoading || isUpdateLoading || isDataLoading);

    React.useEffect(() => {
        if (device) {
            form.reset(cleanReadDataForUpdate(device));
        } else {
            form.reset(DEFAULT_CREATE_VALUES);
        }
    }, [device, form.reset]);

    const handleClose = () => {
        setOpen(false);
    }

    const sendData = (data: IDeviceCreate | IDeviceUpdate) => {
        if (createMode) {
            triggerCreate(data);
        } else {
            triggerUpdate({
                id: deviceId || "", ...data
            });
        }
    };

    // Handle error and success notifications
    useEffect(() => {
        if (isCreateError) {
            enqueueSnackbar("Errore creazione", { variant: "error" });
        }
        if (isCreateSuccess) {
            enqueueSnackbar("Creato con successo", { variant: "success" });
            handleClose();
        }
        if (isUpdateError) {
            enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
        }
        if (isUpdateSuccess) {
            enqueueSnackbar("Aggiornato con successo", { variant: "success" });
            handleClose();
        }
    }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

    const openButton = render ? render({ onClick: () => setOpen(true) }) : <IconButton onClick={() => setOpen(true)}><EditOutlinedIcon /></IconButton>;

    return (
        <>
            {openButton}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>{createMode ? "Crea nuovo dispositivo" : "Modifica dispositivo"}</DialogTitle>
                {!isDataLoading ? (
                    <form onSubmit={form.handleSubmit(sendData)}>
                        <DialogContent>
                            <Stack gap={2}>
                                <Controller
                                    name="name"
                                    rules={{ required: true, minLength: 3 }}
                                    control={form.control}
                                    defaultValue=""
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            autoFocus
                                            label={"Nome"}
                                            type="text"
                                            fullWidth
                                            required
                                            error={!!fieldState.error}
                                            helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                                            {...field}
                                        />
                                    )}
                                />
                                {/* <Controller
                                    name="organization_id"
                                    rules={{ required: true }}
                                    control={form.control}
                                    defaultValue=""
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            label={"ID Organizzazione"}
                                            type="text"
                                            fullWidth
                                            required
                                            error={!!fieldState.error}
                                            helperText={!!fieldState.error && "Il campo è obbligatorio"}
                                            {...field}
                                        />
                                    )}
                                /> */}
                                <Controller
                                    name="tag_ids"
                                    control={form.control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <TagSelect {...field} />
                                    )}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ m: 2 }}>
                            <Button
                                startIcon={<CloseOutlinedIcon />}
                                onClick={handleClose}>Annulla</Button>
                            <LoadingButton
                                startIcon={<CheckOutlinedIcon />}
                                loading={isLoading}
                                disabled={isLoading}
                                variant='contained' type="submit">{createMode ? "Crea" : "Aggiorna"}</LoadingButton>
                        </DialogActions>
                    </form>
                ) : (
                    <DialogContent>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}


export default DeviceFormDialog;