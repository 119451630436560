import React, { useEffect } from "react";
import { AuthContext } from "./AuthContext";
import {
  useLoginMutation,
  useLogoutMutation,
  useCurrentUserQuery,
} from "@app/services/appApi";
import { enqueueSnackbar } from "notistack";
import { IUserRead } from "./types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";

interface Props { }

const AuthContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
  // Init user to null if no token is present, undefined if there is so we can show a loading indicator
  const [user, setUser] = React.useState<IUserRead | null | undefined>(
    localStorage.getItem("token") ? undefined : null
  );

  // Prepare API calls
  const [login, { data: loginData, isError, isSuccess }] = useLoginMutation();
  const [logout, { isError: isLogoutError, isSuccess: isLogoutSuccess }] =
    useLogoutMutation();
  const {
    data,
    isError: isUserError,
    isSuccess: isUserSuccess,
    error: userError,
  } = useCurrentUserQuery();

  // handle login success: save token to localstorage
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar("Login successful", { variant: "success" });
    }
  }, [isSuccess, loginData]);

  // Handle logout success: clear the token from localstorage and set user to null
  useEffect(() => {
    if (isLogoutSuccess) {
      setUser(null);
      enqueueSnackbar("Logout successful", { variant: "success" });
    }
  }, [isLogoutSuccess]);

  // Handle login errors: show a toast message
  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Login failed", { variant: "error" });
    }
  }, [isError]);

  // Handle logout errors: show a toast message
  useEffect(() => {
    if (isLogoutError) {
      enqueueSnackbar("Logout failed", { variant: "error" });
    }
  }, [isLogoutError]);

  // Handle current user updates: set or clear the user
  useEffect(() => {
    if (isUserSuccess && data) {
      // We have a user, set it
      console.log("setting user");
      setUser(data);
    } else if (
      isUserError &&
      (userError as FetchBaseQueryError).status === 401
    ) {
      // Request unauthorized, remove token and set user to null
      console.log("setting user to null");
      setUser(null);
    }
  }, [isUserSuccess, isUserError, data, userError]);

  const isLoggedIn: boolean = user !== null && user !== undefined;

  return (
    <AuthContext.Provider value={{ user, isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
