import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import DebugPage from "./features/debug/DebugPage";
import { Role } from "./core/auth/authWrappers";
import AuthContextProvider from "./core/auth/AuthProvider";
import LoginPage from "./features/auth/LoginPage";
import { AssistantProvider } from "./features/assistant/useAssistantContext";
import './i18n';
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import NoteListPage from "./features/note/NoteListPage";
import DeviceListPage from "./features/device/DeviceListPage";
import SettingsPage from "./features/Settings";
import LogViewer from "./features/logging/logViewer";
import ProfilePage from "./features/auth/Profile";
import FileManagerPage from "./features/file-manager/FileManagerPage";
import OrganizationListPage from "./features/organization/organizationListPage";
import UserListPage from "./features/user/userListPage";
import { Page } from "@core/layout/page";
import TagListPage from "@features/tags/TagListPage";
function App() {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthContextProvider>
          <AssistantProvider>
            <SnackbarProvider autoHideDuration={4000} />
            <CssBaseline />
            <Routes>

              <Route
                path="/organizations"
                element={<OrganizationListPage allowedRoles={[Role.User]} />}
              />
              <Route
                path="/users"
                element={<UserListPage allowedRoles={[Role.User]} />}
              />

              <Route
                path="/profile"
                element={<ProfilePage allowedRoles={[Role.User]} />}
              />
              <Route
                path="/files"
                element={<FileManagerPage allowedRoles={[Role.User]} />}
              />
              <Route
                path="/"
                element={<LogViewer allowedRoles={[Role.User]} />}
              />
              <Route
                path="/settings"
                element={<SettingsPage allowedRoles={[Role.User]} />}
              />
              <Route
                path="/devices"
                element={<DeviceListPage allowedRoles={[Role.User]} />}
              />
              <Route
                path="/notes"
                element={<NoteListPage allowedRoles={[Role.User]} />}
              />
              <Route
                path="/tags"
                element={<TagListPage allowedRoles={[Role.User]} />}
              />
              <Route path="/debug" element={<DebugPage />} />
              <Route path="/login" element={<LoginPage />} />
            </Routes>
          </AssistantProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
