import { createTheme, responsiveFontSizes } from "@mui/material";
import { blue, purple, red, green, orange, yellow, grey } from "@mui/material/colors";

// https://mui.com/customization/default-theme/
// https://zenoo.github.io/mui-theme-creator

// use module augmentation to add custom variables to the Theme
declare module '@mui/material/styles' {

    interface Theme {
        app: {
            name: string;
            caption: string;
            contactUrl: string;
            logo: {
                square: {
                    bgColor: string;
                    borderRadius: number;
                    width: number;
                    height: number;
                    imgSrc: string;
                }
            },
            poweredBy: {
                showInNav: boolean;
                showInLoading: boolean;
                url: string;
            },
            nav: {
                bgColor: string;
                color: string;
            }
        },
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        app?: {
            name?: string;
            caption?: string;
            contactUrl?: string;
            logo?: {
                square?: {
                    bgColor?: string;
                    borderRadius?: number;
                    width?: number;
                    height?: number;
                    imgSrc?: string;
                }
            },
            poweredBy?: {
                showInNav?: boolean;
                showInLoading?: boolean;
                url?: string;
            },
            nav?: {
                bgColor?: string;
                color?: string;
            }
        },

    }
}


const willieMateTheme = createTheme({
    palette: {
        // text: {
        //     primary: grey["500"],
        //     // secondary: grey[700],
        // },
        primary: {
            main: "#624E90",
            contrastText: grey[50],
        },
        secondary: {
            main: "#5C2DC8",
        },
        // background: {
        //     default: "#fafafa",
        // },
    },
    app: {
        // Name shown in the nav bar
        name: 'Willie Mate',
        // Displayed under the name. leave empty to disable
        caption: "by J & W ",
        // Leave empty to disable the link
        contactUrl: "https://jay2you.com/en/#contact",
        logo: {
            // Displayed in nav bar & loading screen
            square: {
                bgColor: grey[100],
                borderRadius: 10,
                width: 40,
                height: 40,
                imgSrc: '/static/logo/jw-square.svg',
                // imgSrc: '/static/logo/mnet_square.svg',
            }
        },
        poweredBy: {
            showInNav: true,
            showInLoading: true,
            // Leave empty to disable the link
            url: "https://mastranet.ai?utm_source=Willie Mate",
        },
        nav: {
            color: "#e1e1e1",
            bgColor: '#13003e',
        }
    },
});

const ddxTheme = createTheme({
    palette: {
        // text: {
        //     primary: grey["500"],
        //     // secondary: grey[700],
        // },
        primary: {
            main: "#5aa340",
            contrastText: grey[50],
        },
        secondary: {
            main: "#5C2DC8",
        },
        // background: {
        //     default: "#fafafa",
        // },
    },
    app: {
        // Name shown in the nav bar
        name: 'DDX Assistant',
        // Displayed under the name. leave empty to disable
        caption: "by DDX Group",
        // Leave empty to disable the link
        contactUrl: "https://www.ddxgroup.com/",
        logo: {
            // Displayed in nav bar & loading screen
            square: {
                bgColor: grey[100],
                borderRadius: 10,
                width: 40,
                height: 40,
                imgSrc: '/static/logo/ddxlogo.png',
            }
        },
        poweredBy: {
            showInNav: true,
            showInLoading: true,
            // Leave empty to disable the link
            url: "https://mastranet.ai?utm_source=DDX Assistant",
        },
        nav: {
            color: "#e1e1e1",
            bgColor: '#333',
        }
    },
});

const defaultTheme = createTheme({
    palette: {
        // text: {
        //     primary: grey["500"],
        //     // secondary: grey[700],
        // },
        // primary: {
        //     main: "#624E90",
        //     contrastText: grey[50],
        // },
        // secondary: {
        //     main: "#5C2DC8",
        // },
        // background: {
        //     default: "#fafafa",
        // },
    },
    app: {
        // Name shown in the nav bar
        name: 'MIRA',
        // Displayed under the name. leave empty to disable
        caption: "by Mastranet AI ",
        // Leave empty to disable the link
        contactUrl: "https://mastranet.ai/contact-us",
        logo: {
            // Displayed in nav bar & loading screen
            square: {
                bgColor: grey[100],
                borderRadius: 10,
                width: 40,
                height: 40,
                imgSrc: '/static/logo/mnet_square.svg',
            }
        },
        poweredBy: {
            showInNav: true,
            showInLoading: true,
            // Leave empty to disable the link
            url: "https://mastranet.ai?utm_source=MIRA",
        },
        nav: {
            color: "#e1e1e1",
            bgColor: '#13003e',
        }
    },
});


const getTheme = () => {
    if (/.*\.jay2you\.(com|local)$/.test(window.location.hostname)) {
        // Domain ends with jay2you.com, use Willie Mate theme
        return willieMateTheme
    } else if (/.*\.ddx\.mastranet\.(ai|local)$/.test(window.location.hostname)) {
        // Domain ends in *.ddx.mastranet.ai, use DDX theme
        return ddxTheme
    }
    // Default theme
    return defaultTheme
}


export default responsiveFontSizes(getTheme());