import React from 'react';
import Box from '@mui/material/Box';
import AssistantFloatingBtn from './features/assistant/AssistantFloatingBtn';
import { NavDrawer, RenderNavItemsProps } from "./core/layout/nav";
import { useTranslation } from "react-i18next";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import theme from "./theme";
import EditNoteIcon from '@mui/icons-material/EditNote';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import useAuthContext from "@core/auth/AuthContext";
// Import a suitable icon for tags
import TagIcon from '@mui/icons-material/Tag';

interface AppLayoutProps {
  children: React.ReactNode;
  showNavDrawer?: boolean;
}

export const AppLayout: React.FC<AppLayoutProps> = ({ children, showNavDrawer = true }) => {
  const { t } = useTranslation();
  const authContext = useAuthContext();

  const renderNavItems: RenderNavItemsProps = (props) => ([
    {
      icon: <ListAltOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Log Viewer"),
      url: "/",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <PrecisionManufacturingOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Device", { count: 2 }),
      url: "/devices",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },

    {
      icon: <EditNoteIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Note", { count: 2 }),
      url: "/notes",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <FolderOpenOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: t("File manager"),
      url: "/files",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <PeopleAltOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: t("User", { count: 2 }),
      url: "/users",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <BusinessIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Organization", { count: 2 }),
      url: "/organizations",
      isVisible: props.authContext.isLoggedIn && !!props.authContext.user?.organization?.max_child_orgs,
      position: "top",
    },
    {
      icon: <TagIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Tags", { count: 2 }),
      url: "/tags",
      isVisible: props.authContext.isLoggedIn,
      position: "top",
    },
    {
      icon: <BugReportOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: t("Debug"),
      url: "/debug",
      isVisible: props.authContext.isLoggedIn && process.env.NODE_ENV === "development",
      position: "top",
    },
    {
      icon: <AccountCircleOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: props.authContext.user ? props.authContext.user.username : "Login",
      url: props.authContext.user ? "/profile" : "/login",
      position: "bottom",
    },
    {
      icon: <HelpOutlineIcon sx={{ color: theme.app.nav.color }} />,
      text: "Contact us",
      url: "https://www.mastranet.ai/contact-us",
      isVisible: true,
      position: "bottom",
    },
    {
      icon: <SettingsOutlinedIcon sx={{ color: theme.app.nav.color }} />,
      text: "Settings",
      url: "/settings",
      isVisible: props.authContext.isLoggedIn,
      position: "bottom",
    }
  ])

  if (!showNavDrawer) {
    return <>{children}</>;
  }

  return (
    <NavDrawer renderNavItems={renderNavItems}>
      <Box sx={{ p: 4, width: "100%" }}>{children}</Box>
      {authContext.isLoggedIn && <AssistantFloatingBtn />}
    </NavDrawer>
  );
};


interface WithAppLayoutProps {
  showNavDrawer?: boolean;
  // Add any other props that come with the component you want to wrap
  [key: string]: any;
}

export const withAppLayout = <P extends object>(Component: React.ComponentType<P>) => {
  const WithAppLayout = ({ showNavDrawer = true, ...props }: WithAppLayoutProps) => {
    return (
      <AppLayout showNavDrawer={showNavDrawer}>
        <Component {...(props as P)} />
      </AppLayout>
    );
  };

  return WithAppLayout;
};
