import Typography from "@mui/material/Typography";
import { t } from 'i18next';
import { useGetDevicesQuery } from "../../app/services/appApi";
import { useAssistantContext } from "@features/assistant/useAssistantContext";
import { IDeviceRead } from './deviceTypes';
import Select from '@features/input/select/Select';

export default function DeviceSelect() {

    const { relevDeviceId, setRelevDeviceId } = useAssistantContext()
    const { data: devices } = useGetDevicesQuery({ page: 1, pageSize: 100, search: "" })

    const options = devices?.results?.map((device: IDeviceRead) => ({
        value: device.id,
        label: device.name
    }))


    if (!options) {
        return (
            <Typography variant="body2" color="textSecondary">
                {t("Loading devices...")}
            </Typography>
        )
    }

    if (options.length === 0) {
        return (
            <Typography variant="body2" color="textSecondary">
                {t("No devices found")}
            </Typography>
        )
    }

    return (
        <Select
            label={t("Device", { count: 1 })}
            options={options}
            value={relevDeviceId || ""}
            onChange={(e) => setRelevDeviceId(e.target.value as IDeviceRead["id"])}
            formControlProps={{ sx: { mt: 3, maxWidth: "250px" }, fullWidth: true }}
        />
    )
}
