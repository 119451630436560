import React, { useEffect, useState } from "react";
import {
    useGetUsersQuery,
    useDeleteUserMutation,
} from "../../app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { withAuth } from "../../core/auth/authWrappers";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import UserFormDialog from "./userFormDialog";
import AddIcon from '@mui/icons-material/Add';
import { IUserRead } from "../../core/auth/types";
import { withAppLayout } from "../../AppLayout";
import { Page } from "@core/layout/page";
import { SimpleSearchBar } from "@core/input";

const UserListPage = () => {
    const { t } = useTranslation();


    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    });

    const [searchValue, setSearchValue] = useState("");
    const { data, isLoading } = useGetUsersQuery(
        {
            page: paginationModel.page + 1,
            pageSize: paginationModel.pageSize,
            search: searchValue,
        }
    );


    const columns: GridColDef[] = [
        {
            field: "id", headerName: "ID", width: 80
        },
        {
            field: "username",
            headerName: t("Username"),
            // Grow the column width
            flex: 1,
        },
        {
            field: "organization",
            headerName: t("Organization", { count: 1 }),
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => params.row.organization?.name,
        },
        // role
        {
            field: "role",
            headerName: t("Role"),
            flex: 1,
            valueGetter: (params: GridValueGetterParams) => t(params.row.role),
        },
        {
            field: "edit", headerName: "",
            //@ts-ignore-next-line
            renderCell: (params: GridValueGetterParams) => (
                <UserFormDialog userId={params.row.id} />
            ),
        },
        {
            field: "delete", headerName: "",
            //@ts-ignore-next-line
            renderCell: (params: GridValueGetterParams) => (
                <UserDeleteBtn userId={params.row.id} />
            ),
        },
    ];

    const actions = (
        <>
            <SimpleSearchBar onSearch={setSearchValue} size="small" />
            <UserFormDialog render={(props: any) => (
                <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    {...props}
                >
                    {t("Add")}
                </Button>
            )} />
        </>
    );

    return (
        <Page title={t("User", { count: 2 })} actions={actions}>
            <DataGrid
                disableRowSelectionOnClick
                rows={data?.results || []}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                pageSizeOptions={[20, 50, 100]}
                sx={{ height: "80vh", my: 2 }}
                loading={isLoading}
                paginationMode="server"
                rowCount={data?.count || 0}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
        </Page>
    );
}





const UserDeleteBtn: React.FC<{ userId: IUserRead["id"] }> = ({ userId }) => {
    const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteUserMutation();
    const { t } = useTranslation();

    // Handle error and success notifications
    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(t("Model deleted", { model: "User", count: 1 }), { variant: "success" });
        }
        if (isError) {
            enqueueSnackbar(t("Model deletion failed", { model: "User", count: 1 }), { variant: "error" });
        }
    }, [isSuccess, isError, t]);


    return (
        <IconButton
            onClick={() => triggerDelete(userId)}
            color="error"
            disabled={isLoading}
        >
            <DeleteOutlineOutlinedIcon />
        </IconButton>
    )


}

export default withAppLayout(withAuth(UserListPage));
