import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';

const LanguageSelect: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState<string>(
        localStorage.getItem("lng") || "it"
    );

    const handleChangeLanguage = (event: SelectChangeEvent<string>) => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
        localStorage.setItem("lng", newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="language-select-label">{t("Language")}</InputLabel>
            <Select
                label="Language"
                labelId="language-select-label"
                value={selectedLanguage}
                onChange={handleChangeLanguage}
            >
                <MenuItem value="it">Italiano</MenuItem>
                <MenuItem value="en">English</MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSelect;
