import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetTagQuery, useCreateTagMutation, useUpdateTagMutation } from '@app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { ITagRead, ITagCreate, ITagUpdate } from './tagTypes';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react'

const DEFAULT_CREATE_VALUES: ITagCreate = {
    name: "",
}

const cleanReadTagDataForUpdate = (tag: ITagRead): ITagUpdate => {
    const { name } = tag;
    return { name };
}

interface ITagFormDialogProps {
    tagId?: ITagRead["id"];
    render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing tags.
 * It can be opened in two modes:
 * 1. Create mode: tagId is not given
 * 2. Edit mode: tagId is given
 *
 * In edit mode, the tag data is fetched from the API so
 * that the form can be pre-filled with the existing tag data.
 */
const TagFormDialog: React.FC<ITagFormDialogProps> = ({ tagId, render }) => {
    const [open, setOpen] = useState(false);

    const createMode: boolean = !tagId;

    const form = useForm<ITagCreate | ITagUpdate>();

    // Delay data fetching until tagId is defined and dialog is opened
    const { data: tag, isLoading: isDataLoading } = useGetTagQuery(tagId ?? skipToken, { skip: !open });

    const [triggerCreate, {
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        isError: isCreateError

    }] = useCreateTagMutation();
    const [triggerUpdate, {
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError
    }] = useUpdateTagMutation();

    const isLoading = (isCreateLoading || isUpdateLoading || isDataLoading);

    React.useEffect(() => {
        if (tag) {
            form.reset(cleanReadTagDataForUpdate(tag));
        } else {
            form.reset(DEFAULT_CREATE_VALUES);
        }
    }, [tag, form.reset]);

    const handleClose = () => {
        setOpen(false);
    }

    const sendData = (data: ITagCreate | ITagUpdate) => {
        if (createMode) {
            triggerCreate(data);
        } else {
            triggerUpdate({
                id: tagId || "", ...data
            });
        }
    };

    // Handle error and success notifications
    useEffect(() => {
        if (isCreateError) {
            enqueueSnackbar("Errore creazione", { variant: "error" });
        }
        if (isCreateSuccess) {
            enqueueSnackbar("Creato con successo", { variant: "success" });
            handleClose();
        }
        if (isUpdateError) {
            enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
        }
        if (isUpdateSuccess) {
            enqueueSnackbar("Aggiornato con successo", { variant: "success" });
            handleClose();
        }
    }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

    const openButton = render ? render({ onClick: () => setOpen(true) }) : <IconButton onClick={() => setOpen(true)}><EditOutlinedIcon /></IconButton>;

    return (
        <>
            {openButton}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>{createMode ? "Crea nuovo tag" : "Modifica tag"}</DialogTitle>
                {!isDataLoading ? (
                    <form onSubmit={form.handleSubmit(sendData)}>
                        <DialogContent>
                            <Stack gap={2}>
                                <Controller
                                    name="name"
                                    rules={{ required: true, minLength: 3 }}
                                    control={form.control}
                                    defaultValue=""
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            autoFocus
                                            label={"Tagname"}
                                            type="text"
                                            fullWidth
                                            required
                                            error={!!fieldState.error}
                                            helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                                            {...field}
                                        />
                                    )}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ m: 2 }}>
                            <Button
                                startIcon={<CloseOutlinedIcon />}
                                onClick={handleClose}>Annulla</Button>
                            <LoadingButton
                                startIcon={<CheckOutlinedIcon />}
                                loading={isLoading}
                                disabled={isLoading}
                                variant='contained' type="submit">{createMode ? "Crea" : "Aggiorna"}</LoadingButton>
                        </DialogActions>
                    </form>
                ) : (
                    <DialogContent>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}


export default TagFormDialog;