import { IDeviceRead } from '@features/device/deviceTypes';
import React, { createContext, useContext, ReactNode, useState } from 'react';


interface AssistantContextProps {
    relevLogRecordId: number | undefined;
    setRelevLogRecordId: (id: number | undefined) => void;

    relevDeviceId?: IDeviceRead["id"];
    setRelevDeviceId: (id: IDeviceRead["id"]) => void;

    // relevDocumentationIds: number;
    // setRelevDocumentationIds: (ids: number) => void;
}

const AssistantContext = createContext<AssistantContextProps | undefined>(undefined);

function AssistantProvider({ children }: { children: ReactNode; }) {

    const [relevLogRecordId, setRelevLogRecordId] = useState<number | undefined>();
    const [relevDeviceId, setRelevDeviceId] = useState<IDeviceRead["id"] | undefined>();

    // const [relevDocumentationIds, setRelevDocumentationIds] = useState<number[]>([]);


    // relevantCtx is an object that contains all the relevant context for the assistant
    const assistantCtx: AssistantContextProps = {

        relevLogRecordId,
        setRelevLogRecordId,

        relevDeviceId,
        setRelevDeviceId,

        // relevDocumentationIds,
        // setRelevDocumentationIds,
    };

    return (
        <AssistantContext.Provider value={assistantCtx} >
            {children}
        </AssistantContext.Provider >
    );
}

function useAssistantContext() {
    const context = useContext(AssistantContext);
    if (context === undefined) {
        throw new Error('Must be used inside an AssistantContext Provider');
    }
    return context;
}

export { AssistantProvider, useAssistantContext };
