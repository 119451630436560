import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { IChat } from "../../../app/services/apiTypes";
import EmptyHistoryPlaceholder from "./EmptyHistoryPlaceholder";
import { ChatMessage } from "./ChatMessage";
import { useTranslation } from 'react-i18next';

export interface IChatHistoryProps {
  chat?: IChat;
  onStartChat: () => void;
  loading: boolean;
}


export default function ChatHistory({ chat, loading, onStartChat }: IChatHistoryProps) {
  const { t } = useTranslation();
  const messages = chat?.messages || [];

  return (
    <>
      {loading || messages.length === 0 ? (
        <EmptyHistoryPlaceholder onStartChat={onStartChat} loading={loading} />
      ) : (
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" component="div" align="center">
            {t('AI Assistant')}
          </Typography>
          {chat && chat.messages.length > 0 && (
            <Typography variant="body2" component="div" align="center">
              {t('Device', { count: 1 })}: {chat.messages[0].context_device.name || t('Unknown')}
            </Typography>)
          }
          <List>
            {messages.map((chatMessage) => (
              <ChatMessage
                key={chatMessage.id}
                chatMessage={chatMessage}
                alignRight={!chatMessage.author}
              />
            ))}
          </List>
        </Box>
      )}
    </>
  );
}