
import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { AppLogo } from "./AppLogo";
import { useTheme } from '@mui/material/styles';
import { Typography } from "@mui/material";

// const DrawerHeader = styled("div")(({ theme }) => ({
//     display: "flex",
//     alignItems: "center",
//     padding: theme.spacing(3),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
// }));

interface INavDrawerHeaderProps {
    onClick: () => void;
    open: boolean;
}

export default function NavDrawerHeader({ onClick, open }: INavDrawerHeaderProps) {

    const theme = useTheme();

    return (
        <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
                onClick={onClick}
                sx={{
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    py: 1.5,
                    fontWeight: "bold",
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                    }}
                >
                    <AppLogo />
                </ListItemIcon>
                <ListItemText
                    primary={<Typography variant="h6">
                        <b>
                            {theme.app.name}
                        </b>
                    </Typography>}
                    secondary={
                        <Typography variant="body2">
                            {theme.app.caption}
                        </Typography>
                    }
                    sx={{
                        opacity: open ? 1 : 0,
                        color: theme.app.nav.color,
                        // Secondary text color
                        "& .MuiListItemText-secondary": { color: theme.app.nav.color },
                    }}
                />
            </ListItemButton>
        </ListItem>
    )
}
