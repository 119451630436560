import React, { useState } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    alpha,
} from "@mui/material";
import { styled } from "@mui/system";
import {
    CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

interface DropZoneProps {
    isActive: boolean;
    hasFile: boolean;
}

const DropZone = styled(Box)<DropZoneProps>(({ theme, isActive, hasFile }) => ({
    border: `2px dashed ${isActive ? theme.palette.primary.main : theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(4),
    textAlign: 'center',
    color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
    backgroundColor: isActive
        ? alpha(theme.palette.primary.light, 0.1)
        : (hasFile ? alpha(theme.palette.primary.light, 0.05) : 'transparent'),
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.light, 0.1),
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
    },
}));
const FileUploadDialog: React.FC<{
    open: boolean;
    onClose: () => void;
    onUpload: (file: File) => void;
}> = ({ open, onClose, onUpload }) => {
    const { t } = useTranslation();
    const [file, setFile] = useState<File | null>(null);

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop: (item: { files: File[] }) => {
            if (item.files && item.files.length && item.files[0].type === 'application/pdf') {
                setFile(item.files[0]);
            } else {
                // Optionally, show an error message
                alert(t('Only PDF files are supported'));
            }
        },
        collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });


    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile && selectedFile.type === 'application/pdf') {
            setFile(selectedFile);
        } else {
            // Optionally, show an error message
            alert(t('Only PDF files are supported'));
        }
    };


    const handleUpload = () => {
        if (file) {
            onUpload(file);
            setFile(null);
            onClose();
        }
    };

    const isActive = canDrop && isOver;
    const hasFile = !!file;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{t("Upload File")}</DialogTitle>
            <DialogContent>
                <input
                    type="file"
                    id="file-input"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    accept="application/pdf"
                />
                <label htmlFor="file-input">
                    <DropZone
                        ref={drop}
                        isActive={isActive}
                        hasFile={hasFile}
                    >
                        <CloudUploadIcon sx={{ fontSize: 48, mb: 2 }} />
                        <Typography variant="h6" component="div" gutterBottom>
                            {file ? file.name : t('Drag & Drop file here')}
                        </Typography>
                        <Typography variant="body2">
                            {file ? t('File selected') : t('or click to select PDF file')}
                        </Typography>
                    </DropZone>
                </label>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("Cancel")}</Button>
                <Button onClick={handleUpload} disabled={!file} variant="contained" color="primary">
                    {t("Upload")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FileUploadDialog;