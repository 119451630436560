import { Stack } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormContext } from 'react-hook-form';

interface QuillInputProps {
    textFieldName: string;
    htmlFieldName: string;
}

export default function QuillInput({ textFieldName, htmlFieldName }: QuillInputProps) {
    const form = useFormContext();

    const handleChange = (_content: any, _delta: any, _source: any, editor: any) => {
        form.setValue(textFieldName, editor.getText());
        form.setValue(htmlFieldName, editor.getHTML());
    };

    return (
        <>
            <ReactQuill
                theme="snow"
                value={form.watch(htmlFieldName) || form.watch(textFieldName) || ''}
                onChange={handleChange}
            />
            <Stack direction="row" gap={1}>
                <input type="hidden" {...form.register(textFieldName)} />
                <input type="hidden" {...form.register(htmlFieldName)} />
            </Stack>
        </>
    );
}