import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IChat,
  IDebugResponse,
  IListResponse,
  ILogRecord,
  ILogRecordRequest,
  IChatMessage,
  IChatMessageRequest,
  IRelatedLogRecordResult,
  ISearchRequest,
  ITopKRequest,
  ITopKResponse
} from "./apiTypes";
import { IOrganizationRead, IOrganizationCreate, IOrganizationUpdate
 } from "@features/organization/organizationTypes";
import { IUserCreate, IUserRead, IUserUpdate, ILoginRequest, ILoginResponse} from "@core/auth/types";
import { IDeviceCreate, IDeviceRead, IDeviceUpdate} from "@features/device/deviceTypes";
import { INoteCreate, INoteRead, INoteUpdate} from "@features/note/noteTypes";
import { store } from "../store";
import { IStorageFileRead, IStorageFileUpdate} from "@features/file-manager/storageFileTypes";
import { ITagCreate, ITagRead, ITagUpdate } from "@features/tags/tagTypes";
import { get } from "http";

const API_PATH = "/api/v1/"; // Only url part, without localhost or IP. Must always end with a slash


function invalidateUserTag(api: any) {
  // TODO: does this work?
  console.log("Invalidating USER tag");
  store.dispatch(api.util.invalidateTags(["USER"]))
}

const baseQuery =fetchBaseQuery({
  baseUrl: API_PATH,
  prepareHeaders: (headers, { getState }) => {
    // Add default Content-Type header unless they are already set. case-insensitive
    // if (!headers.has("Content-Type") && !headers.has("content-type")) {
    //   headers.set("Content-Type", "application/json");
    // }
    headers.set("Accept", "application/json; charset=utf-8");
    // Append token to every request
    const token = localStorage.getItem("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithLogout = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  // Handle authentication errors
  if (result.error && result.error.status === 401) {
    // const isMissingToken = result.error.data.code === "MISSING_TOKEN";
    const isInvalidToken = (result.error.data as { code?: string })?.code === "INVALID_TOKEN";
    const isExpiredToken = (result.error.data as { code?: string })?.code === "EXPIRED_TOKEN";

    if (isInvalidToken || isExpiredToken) {
      console.log("Invalid or missing token, logging out...");
      localStorage.removeItem("token");
      // Invalidate Auth cache
      invalidateUserTag(api);
    }
  }

  return result;
};

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: baseQueryWithLogout,
  tagTypes: ["USER", "LOG_RECORD", "CHAT", "NOTE", "DEVICE", "TAG", "ORGANIZATION", "FILE"],

  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: ({ username, password }) => ({
        url: "/user/login",
        method: "POST",
        body: { username, password },
      }),
      // Update auth only on successful attempt to refetch user data
      invalidatesTags: (result) => (result ? ["USER", "LOG_RECORD", "CHAT", "NOTE", "DEVICE", "TAG", "ORGANIZATION"] : []),
      // Keep token in localStorage
      transformResponse: (response: ILoginResponse) => {
        if (response.token) {
          localStorage.setItem("token", response.token);
        }
        return response;
      },
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/user/logout",
        method: "POST",
      }),
      transformResponse: (response: void) => {
        // Remove token from localStorage
        localStorage.removeItem("token");
        return response;
      },
    }),

    currentUser: builder.query<IUserRead, void>({
      query: () => "/user/current",
      providesTags: ["USER"],
    }),


    getUsers: builder.query<IListResponse<IUserRead>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/user?${queryParams.toString()}`;
      },
      providesTags: ["USER"],
    }),

    getUser: builder.query<IUserRead, IUserRead["id"]>({
      query: (userId) => `/user/${userId}`,
      providesTags: ["USER"],
    }),

    createUser: builder.mutation<IUserRead, IUserCreate>({
      query: (user) => ({
        url: `/user`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["USER"],
    }),

    updateUser: builder.mutation<
      IUserRead,
      IUserUpdate & { id: IUserRead["id"] }
    >({
      query: ({ id, ...user }) => ({
        url: `/user/${id}`,
        method: "PUT",
        body: user,
      }),
      invalidatesTags: ["USER"],
    }),

    deleteUser: builder.mutation<void, IUserRead["id"]>({
      query: (userId) => ({
        url: `/user/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["USER"],
    }),


    getOrganizations: builder.query<IListResponse<IOrganizationRead>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/organization?${queryParams.toString()}`;
      },
        providesTags: ["ORGANIZATION"],
    }),


    getOrganization: builder.query<IOrganizationRead, IOrganizationRead["id"]>({
      query: (organizationId) => `/organization/${organizationId}`,
      providesTags: ["ORGANIZATION"],
    }),



    createOrganization: builder.mutation<IOrganizationRead, IOrganizationCreate>({
      query: (organization) => ({
        url: `/organization`,
        method: "POST",
        body: organization,
      }),
      invalidatesTags: ["ORGANIZATION"],
    }),

    deleteOrganization: builder.mutation<void, IOrganizationRead["id"]>({
      query: (organizationId) => ({
        url: `/organization/${organizationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ORGANIZATION", "USER"], // Users are deleted when their organization is deleted
    }),


    updateOrganization: builder.mutation<
    IOrganizationRead,
    IOrganizationUpdate & { id: IOrganizationRead["id"] }
    >({
      query: ({ id, ...organization }) => ({
        url: `/organization/${id}`,
        method: "PUT",
        body: organization,
      }),
      invalidatesTags: ["ORGANIZATION", "USER"],
    }),



    getLogRecords: builder.query<ILogRecord[], { deviceId: IDeviceRead["id"] }>({
      query: ({deviceId}) => `/logrecord?device_id=${deviceId}`,
      providesTags: ["LOG_RECORD"],
    }),

    //
    // Devices
    //

    getDevices: builder.query<IListResponse<IDeviceRead>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/device?${queryParams.toString()}`;
      },
      providesTags: ["DEVICE"],
    }),

    getDevice: builder.query<IDeviceRead, IDeviceRead["id"]>({
      query: (deviceId) => `/device/${deviceId}`,
      providesTags: ["DEVICE"],
    }),

    createDevice: builder.mutation<IDeviceRead, IDeviceCreate>({
      query: (device) => ({
        url: `/device`,
        method: "POST",
        body: device,
      }),
      invalidatesTags: ["DEVICE"],
    }),

    updateDevice: builder.mutation<
      IDeviceRead,
      IDeviceUpdate & { id: IDeviceRead["id"] }
    >({
      query: ({ id, ...device }) => ({
        url: `/device/${id}`,
        method: "PUT",
        body: device,
      }),
      invalidatesTags: ["DEVICE"],
    }),

    deleteDevice: builder.mutation<void, IDeviceRead["id"]>({
      query: (deviceId) => ({
        url: `/device/${deviceId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DEVICE"],
    }),

    //
    // Tags
    //

    getTags: builder.query<ITagRead[], void>({
      query: () => `/tag`,
      providesTags: ["TAG"],
    }),

    getTag: builder.query<ITagRead, ITagRead["id"]>({
      query: (tagId) => `/tag/${tagId}`,
      providesTags: ["TAG"],
    }),

    createTag: builder.mutation<ITagRead, ITagCreate>({
      query: (tag) => ({
        url: `/tag`,
        method: "POST",
        body: tag,
      }),
      invalidatesTags: ["TAG"],
    }),

    updateTag: builder.mutation<
      ITagRead,
      ITagUpdate & { id: ITagRead["id"] }
    >({
      query: ({ id, ...tag }) => ({
        url: `/tag/${id}`,
        method: "PUT",
        body: tag,
      }),
      invalidatesTags: ["TAG"],
    }),

    deleteTag: builder.mutation<void, ITagRead["id"]>({
      query: (tagId) => ({
        url: `/tag/${tagId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TAG"],
    }),

    getRelatedLogRecords: builder.query<IRelatedLogRecordResult[], number>({
      query: (logRecordId) => `/logrecord/${logRecordId}/related`,
      providesTags: ["LOG_RECORD", "NOTE"],
    }),

    createLogRecord: builder.mutation<ILogRecord, ILogRecordRequest>({
      query: (logRecord) => ({
        url: `/logrecord`,
        method: "POST",
        body: logRecord,
      }),
      invalidatesTags: ["LOG_RECORD"],
    }),

    createChatMessage: builder.mutation<
      IChatMessage,
      { chatId?: number; chatMessage: IChatMessageRequest }
    >({
      query: ({ chatId, chatMessage }) => ({
        // If chatId is undefined, a new chat will be created by the server
        url: chatId ? `/chat/${chatId}/message` : `/chat/message`,
        method: "POST",
        body: chatMessage,
      }),
      invalidatesTags: ["CHAT"],
    }),

    getChat: builder.query<IChat, number>({
      query: (chatId) => `/chat/${chatId}`,
      providesTags: ["CHAT"],
      // transformResponse: (response: any) => {
      //   return ""; // Ignore response JSON, we're streaming data from websocket
      // },
      async onCacheEntryAdded(
        chatId,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        // create a websocket connection when the cache subscription starts.
        // const wsBaseUrl =
        const lorem =
          localStorage.getItem("dbgLoremChat") === "true" ? "true" : "false";
        const wsBaseUrl =
          window.location.origin.replace(/^http/, "ws") + API_PATH;
        const ws = new WebSocket(
          `${wsBaseUrl}chat/${chatId}/stream?lorem=${lorem}`
        );
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;
          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            const msg = event.data;
            if (msg === "<SOF>") {
              // Start of file. Append new temp message
              // console.log("Start of file");
              updateCachedData((draft) => ({
                ...draft,
                messages: [
                  ...draft.messages,
                  {
                    id: -1,
                    body: "",
                  } as IChatMessage,
                ],
              }));
            } else if (msg === "<EOF>") {
              // End of file. Handle this case
              // console.log("End of file");
            } else {
              // console.log("Received message: ", msg);
              updateCachedData((draft) => ({
                ...draft,
                messages: [
                  // Override all messages except the last one
                  ...draft.messages.slice(0, -1),
                  // Override last message's body
                  {
                    ...draft.messages[draft.messages.length - 1],
                    body: draft.messages[draft.messages.length - 1].body + msg,
                  },
                ],
              }));
            }
          };
          ws.addEventListener("message", listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws.close();
      },
    }),

    // createNote: builder.mutation<INote, INoteRequest>({
    //   query: (note) => ({
    //     url: `/note`,
    //     method: "POST",
    //     body: note,
    //   }),
    //   invalidatesTags: ["NOTE", "LOG_RECORD"],
    // }),

    // getNotes: builder.query<[] | [INoteSearchResult], ILogRecord>({
    //   query: (log) => `/logrecord/${log.id}/note/`,
    // }),

    debugInfo: builder.query<IDebugResponse, void>({
      query: () => `/debug`,
    }),

    topKSearch: builder.query<ITopKResponse, ITopKRequest>({
      query: (body) => ({
        url: `/search`,
        method: "POST",
        body,
      }),
    }),


    //
    // Notes
    //
    getNotes: builder.query<IListResponse<INoteRead>, ISearchRequest>({
      query: ({ page, pageSize, search }) => {
        const queryParams = new URLSearchParams();
        if (page !== undefined) {
          queryParams.append("page", page.toString());
        }
        if (pageSize !== undefined) {
          queryParams.append("page_size", pageSize.toString());
        }
        if (search !== undefined && search !== "") {
          queryParams.append("search", search);
        }
        return `/note?${queryParams.toString()}`;
      },
        providesTags: ["NOTE"],
    }),

    getNote: builder.query<INoteRead, INoteRead["id"]>({
      query: (noteId) => `/note/${noteId}`,
      providesTags: ["NOTE"],
    }),

    createNote: builder.mutation<INoteRead, INoteCreate>({
      query: (note) => ({
        url: `/note`,
        method: "POST",
        body: note,
      }),
      invalidatesTags: ["NOTE", "LOG_RECORD"],
    }),

    updateNote: builder.mutation<INoteRead,INoteUpdate & { id: INoteRead["id"] }>({
      query: ({ id, ...note }) => ({
        url: `/note/${id}`,
        method: "PUT",
        body: note,
      }),
      invalidatesTags: ["NOTE"],
    }),

    deleteNote: builder.mutation<void, INoteRead["id"]>({
      query: (noteId) => ({
        url: `/note/${noteId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["NOTE"],
    }),

    forceReindexAllNotes: builder.mutation<void, void>({
      query: () => ({
        url: `/note/force_reindex_all`,
        method: "POST"
      }),
      invalidatesTags: ["NOTE", "LOG_RECORD"],
    }),


    forceReindexAllFiles: builder.mutation<void, void>({
      query: () => ({
        url: `/storage/file/force_reindex_all`,
        method: "POST"
      }),
    }),


    //
    // File manager
    //

    getFile: builder.query<IStorageFileRead, IStorageFileRead["id"]>({
      query: (fileId) => `/storage/file/${fileId}`,
      providesTags: ["FILE"],
    }),

    updateFile: builder.mutation<IStorageFileRead, IStorageFileUpdate & { id: IStorageFileRead["id"] }>({
      query: ({id, ...fileData}) => ({
        url: `/storage/file/${id}`,
        method: "PUT",
        body: fileData
      }),
      invalidatesTags: ["FILE"],
    }),

    deleteFile: builder.mutation<void, { id: IStorageFileRead["id"] }>({
      query: ({ id }) => ({
        url: `/storage/file/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FILE"],
    }),

    getFiles: builder.query<IStorageFileRead[], {path: string}>({
      query: ({ path }) => `/storage/files?path=${encodeURIComponent(path)}`,
      providesTags: ["FILE"],
    }),

    uploadFile: builder.mutation<IStorageFileRead, {path: string, file: File}>({
      query: ({path, file}) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `/storage/upload?path=${encodeURIComponent(path)}`,
          method: "POST",
          body: formData
        };
      },
      invalidatesTags: ["FILE"],
    }),

    downloadFile: builder.query<Blob, {path:string}>({
      query: ({ path }) => ({
        url: `/storage/file?path=${encodeURIComponent(path)}`,
        responseHandler: (response: Response) => response.blob(),
      }),
    }),



  }),
});

export const {
  // Admin and debug
  useDebugInfoQuery,

  // User
  useLoginMutation,
  useLogoutMutation,
  useCurrentUserQuery,
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,


  // Log records
  useGetLogRecordsQuery,
  useGetRelatedLogRecordsQuery,
  useCreateLogRecordMutation,

  // Device
  useGetDevicesQuery,
  useGetDeviceQuery,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,

  // Chat
  useCreateChatMessageMutation,
  useGetChatQuery,

  // Tags
  useGetTagQuery,
  useGetTagsQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,

  // Notes
  useGetNoteQuery,
  useGetNotesQuery,
  useCreateNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,

  // TopKSearch
  useTopKSearchQuery,

  // Debug
  useForceReindexAllFilesMutation,
  useForceReindexAllNotesMutation,

  // Organization
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useGetOrganizationsQuery,
  useDeleteOrganizationMutation,

  // File manager
  useGetFileQuery,
  useGetFilesQuery,
  useUpdateFileMutation,
  useUploadFileMutation,
  useDeleteFileMutation,
  useDownloadFileQuery,

  // vvv Add stuff here vvv

} = appApi;
