import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ILogRecord } from "../../app/services/apiTypes";
import LogLevelChip from "../logging/LogLevelChip";

interface LogRecordDetailProps {
  logRecord: ILogRecord;
}

export default function LogRecordDetail({ logRecord }: LogRecordDetailProps) {
  const { t } = useTranslation();

  return (
    <Card elevation={0} sx={{ m: 2 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {t('Log Details')}
        </Typography>
        <Typography variant="h5" component="div">
          {logRecord.body}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {logRecord.created_at}
        </Typography>
        <LogLevelChip level={logRecord.level} />
      </CardContent>
    </Card>
  );
}
