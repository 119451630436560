import ViewerToolbar from './toolbar'
import { ITopKResult } from '../../app/services/apiTypes'
import PdfViewer from './PDFViewer'
import NoteViewer from './NoteViewer'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

interface ViewerProps {
    resource: ITopKResult
    open: boolean
    onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    // handleNext: () => void
    // handlePrev: () => void
}

function Viewer({
    resource,
    open,
    // handleNext,
    // handlePrev,
    onClose,
}: ViewerProps) {
    return (
        <>
            <Modal
                // sx={{
                //     color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,
                //     backgroundColor: (theme) => "rgba(0, 0, 0, 0.8)",
                //     opacity: (theme) => theme.palette.action.hoverOpacity,
                // }}
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                // onClick={(e) => e.currentTarget === e.target && onClose(e)}
                // open={open}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <>
                    <ViewerToolbar onClose={onClose} />

                    {/* Page count chip */}
                    {/* <Chip
                    style={{
                        position: 'absolute',
                        color: 'white',
                        top: 5,
                        left: 5,
                        zIndex: 100,
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                    label={pageLabel}
                /> */}
                    {/* <Typography variant="h5">{resource.title}</Typography> */}
                    {/* <ViewerIconButton
                    onClick={handleNext}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: 5,
                        zIndex: 100
                    }}
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    size="large"
                >
                    <ChevronRightIcon />
                </ViewerIconButton>
                <ViewerIconButton
                    onClick={handlePrev}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: 5
                    }}
                    size="large"
                >
                <ChevronLeftIcon />
            </ViewerIconButton>*/}
                    <Box
                        sx={{
                            // boxShadow: 24,
                            // bgcolor: 'background.paper',
                            m: 1,
                            width: '100%',
                            maxWidth: 'md', // max width
                            // height: '100%',
                            maxHeight: '97vh',
                            overflowY: 'auto', // make it scrollable if content is bigger
                            display: 'flex', // use flexbox
                            flexDirection: 'column', // stack items vertically
                            justifyContent: 'center', // center items vertically
                            // alignItems: 'center', // center items horizontally
                            borderRadius: 1,

                            // Make it stand out for debugging
                            // border: '1px solid red',
                            // backgroundColor: 'rgba(0,0,0,0.5)',
                        }}
                    >
                        {resource.src_ref_type === "FILE" && <PdfViewer resource={resource} />}
                        {resource.src_ref_type === "NOTE" && <NoteViewer resource={resource} />}
                    </Box >
                </>

            </Modal >
        </>
    )
}

export default Viewer