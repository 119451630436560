import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { ITopKResult } from "../../app/services/apiTypes";
import { Box, Divider, Paper, Typography } from "@mui/material";
import Stack from "@mui/system/Stack";
import IconButton from "@mui/material/IconButton";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import ChatColumn from "./chat/ChatColumn";
import { t } from "i18next";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useTopKSearchQuery } from "../../app/services/appApi";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { Switch, FormControlLabel } from '@mui/material';
import Viewer from "../viewer/viewer";
import { useAssistantContext } from "./useAssistantContext";
import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { IDeviceRead } from "@features/device/deviceTypes";

interface AssistantDrawerProps {
    open: boolean;
    onClose: () => void;
    onMessageSubmit?: (body: string) => void;
}

const AssistantDrawer: React.FC<AssistantDrawerProps> = ({ open, onClose, onMessageSubmit }) => {

    const [query, setQuery] = useState<string>("");
    const { relevDeviceId } = useAssistantContext()
    const [autoUpdate, setAutoUpdate] = useState<boolean>(true);

    const handleMessageSubmit = (body: string) => {
        if (autoUpdate) {
            setQuery(body);
        }
        onMessageSubmit && onMessageSubmit(body);
    }

    const handleClose = () => {
        onClose && onClose();
        setQuery("");
        setAutoUpdate(true);
    }

    return (
        <>
            <Drawer anchor="right" open={open} onClose={handleClose}>
                <Grid container spacing={0} sx={{ width: "100vw", maxWidth: "1200px", height: "100vh" }}>
                    {/* Sidebar #1 */}
                    <Grid xs={12} sm={6} sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
                        {/* Top buttons row */}
                        <Stack direction="row" gap={2} sx={{
                            p: 2
                        }}>
                            {/* Close drawer btn */}
                            <IconButton size="small" onClick={handleClose}>
                                <KeyboardTabIcon />
                            </IconButton>
                            <Box sx={{ flexGrow: 1 }} />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={autoUpdate}
                                        onChange={() => setAutoUpdate(!autoUpdate)}
                                        sx={{ float: "right" }}
                                    />
                                }
                                label={t("Auto update")}
                            />
                        </Stack>
                        <Divider />
                        {relevDeviceId && <RelevantDocsList query={query} deviceId={relevDeviceId} />}
                    </Grid>
                    {/* Sidebar #2 */}
                    <Grid xs={12} sm={6}>
                        <ChatColumn onMessageSubmit={handleMessageSubmit} />
                    </Grid>
                </Grid>
            </Drawer >
        </>
    );
}

function RelevantDocsList({ query, deviceId }: { query: string, deviceId: IDeviceRead["id"] }) {

    const { data: reponse, isFetching, isError } = useTopKSearchQuery(
        { query, device_id: deviceId },
        { skip: !query || !deviceId } // Skip the query if the search query is empty
    )

    useEffect(() => {
        if (isError) {
            enqueueSnackbar("An error occurred while searching relevant docs", {
                variant: "error",
            });
        }
    }, [isError]);

    return (
        <Box sx={{ p: 3, overflow: "auto" }}>
            <Stack direction="row" sx={{ mb: 3, color: "#6f6f6f" }}>
                <PlagiarismOutlinedIcon sx={{ mr: 1 }} />
                <Typography variant="body1">
                    <b>{t('Related Resources')}</b>
                </Typography>
            </Stack>
            {isFetching ? (
                <LoadingSpinner />
            ) : (!reponse || reponse.results.length === 0) ? (
                <NoResultsPlaceholder />
            ) : (
                <Stack gap={3}>
                    {reponse.results.map((result: ITopKResult) => <RelevantResourcePreview result={result} key={`${result.src_ref_type}-${result.src_ref_id}-${result.page_idx}-${result.score}`} />)}
                </Stack>
            )}
        </Box >
    );
}

const NoResultsPlaceholder: React.FC = () => {
    return (<Stack gap={2} alignItems="center" justifyContent="center" sx={{
        my: 2, p: 3, border: "1px dashed #6f6f6f", borderRadius: 4
    }}>
        <SearchOffOutlinedIcon name="noResults" sx={{ fontSize: 48, color: '#6f6f6f' }} />
        <Typography variant="body1" color="text.secondary">
            {t("No results found")}
        </Typography>
    </Stack>)
}

const LoadingSpinner: React.FC = () => {
    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ p: 4, width: "100%" }}
        >
            <CircularProgress />
            <Typography variant="body1" component="span" sx={{ ml: 1, mt: 2 }}>
                Searching...
            </Typography>
        </Stack>
    );
};

export default AssistantDrawer;

function RelevantResourcePreview({ result }: { result: ITopKResult }) {

    const [openViewer, setOpenViewer] = useState<boolean>(false);

    return (<>
        <Paper variant="outlined" elevation={0} sx={{
            p: 3, borderRadius: 4, cursor: "pointer"
        }} onClick={() => setOpenViewer(true)}>
            <Stack direction="row">
                <InsertDriveFileOutlinedIcon color="primary" fontSize="small" />
                <Typography variant="body1" sx={{ ml: 1 }}>
                    {result.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                    - pag. {result.page_idx + 1}
                    {/* {result.subtitle} */}
                </Typography>
            </Stack>
            <Typography variant="body2" color="GrayText" sx={{ mt: 2 }}>
                {result.content.length > 255 ? result.content.slice(0, 255) + "..." : result.content}
            </Typography>
        </Paper>

        <Viewer
            open={openViewer}
            onClose={() => setOpenViewer(false)}
            resource={result}
        />
    </>)
}
