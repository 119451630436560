import React, { useEffect, useState } from "react";
import {
    useGetTagsQuery,
    useDeleteTagMutation,
} from "../../app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { withAuth } from "../../core/auth/authWrappers";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TagFormDialog from "./TagFormDialog";
import AddIcon from '@mui/icons-material/Add';
import { ITagRead } from "./tagTypes";
import { withAppLayout } from "../../AppLayout";
import { Page } from "@core/layout/page";
import { SimpleSearchBar } from "@core/input";

const TagListPage = () => {
    const { data, isLoading } = useGetTagsQuery();

    const { t } = useTranslation();
    const columns: GridColDef[] = [
        {
            field: "id", headerName: "ID", width: 80
        },
        {
            field: "name",
            headerName: t("name"),
            // Grow the column width
            flex: 1,
        },
        {
            field: "edit", headerName: "",
            //@ts-ignore-next-line
            renderCell: (params: GridValueGetterParams) => (
                <TagFormDialog tagId={params.row.id} />
            ),
        },
        {
            field: "delete", headerName: "",
            //@ts-ignore-next-line
            renderCell: (params: GridValueGetterParams) => (
                <TagDeleteBtn tagId={params.row.id} />
            ),
        },
    ];

    const actions = (
        <>
            <TagFormDialog render={(props: any) => (
                <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="primary"
                    {...props}
                >
                    {t("Add")}
                </Button>
            )} />
        </>
    );

    return (
        <Page title={t("Tag", { count: 2 })} actions={actions}>
            <DataGrid
                disableRowSelectionOnClick
                rows={data || []}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 20,
                        },
                    },
                }}
                pageSizeOptions={[20, 50, 100]}
                sx={{ height: "80vh", my: 2 }}
                loading={isLoading}
                paginationMode="client"
            // rowCount={data?.count || 0}
            // paginationModel={paginationModel}
            // onPaginationModelChange={setPaginationModel}
            />
        </Page>
    );
}





const TagDeleteBtn: React.FC<{ tagId: ITagRead["id"] }> = ({ tagId }) => {
    const [triggerDelete, { isLoading, isError, isSuccess }] = useDeleteTagMutation();
    const { t } = useTranslation();

    // Handle error and success notifications
    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar(t("Model deleted", { model: "Tag", count: 1 }), { variant: "success" });
        }
        if (isError) {
            enqueueSnackbar(t("Model deletion failed", { model: "Tag", count: 1 }), { variant: "error" });
        }
    }, [isSuccess, isError, t]);


    return (
        <IconButton
            onClick={() => triggerDelete(tagId)}
            color="error"
            disabled={isLoading}
        >
            <DeleteOutlineOutlinedIcon />
        </IconButton>
    )


}

export default withAppLayout(withAuth(TagListPage));
