import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import PersonIcon from '@mui/icons-material/Person';
import { ReactComponent as AppLogo } from "@assets/mira_logo.svg";


const StyledAvatar = styled(Avatar)(({ theme }) => ({
    marginTop: theme.spacing(2),
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
    backgroundColor: "white",
}));

const LargeStyledAvatar = styled(StyledAvatar)(({ theme }) => ({
    width: theme.spacing(9),
    height: theme.spacing(9),
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.15)",
    margin: theme.spacing(2),
}));

const AvatarWithIcon = ({ Icon, large }: { Icon: React.ReactElement, large?: boolean }) => {
    const AvatarComponent = large ? LargeStyledAvatar : StyledAvatar;

    return (
        <AvatarComponent>
            {Icon}
        </AvatarComponent>
    );
};

const botAvatar = <AvatarWithIcon Icon={<AppLogo />} />;
const largeBotAvatar = <AvatarWithIcon Icon={<AppLogo />} large />;
const userAvatar = <AvatarWithIcon Icon={<PersonIcon color="primary" />} />;

export { botAvatar, largeBotAvatar, userAvatar, AvatarWithIcon };
