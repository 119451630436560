import { ILogRecord } from "../../app/services/apiTypes";
import Chip from "@mui/material/Chip";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function LogLevelChip({
  level,
  variant,
}: {
  level: ILogRecord["level"];
  variant?: "filled" | "outlined";
}) {
  const getStatusIcon = (level: string) => {
    switch (level) {
      case "error":
        return <ErrorIcon />;
      case "warning":
        return <WarningAmberOutlinedIcon />;
      case "info":
        return <InfoOutlinedIcon />;
      default:
        return <ErrorOutlineOutlinedIcon />;
    }
  };

  const capitalize = (s: string) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <Chip
      size="small"
      //@ts-ignore-next-line
      color={level}
      icon={getStatusIcon(level)}
      label={capitalize(level)}
    //   variant={variant}
    />
  );
}
