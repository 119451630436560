import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
const resources = {
  en: {
    translation: require("./langs/en.json"),
  },
  it: {
    translation: require("./langs/it.json"),
  },
};

const storedLng = localStorage.getItem("lng"); // Get the language from local storage

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: storedLng || "it", // Use the stored language if available, otherwise default to "it"
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.services.formatter?.add("lowercase", (value, lng, options) => {
  return value.toLowerCase();
});

export default i18n;
