import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetNoteQuery, useCreateNoteMutation, useUpdateNoteMutation } from '@app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller, FormProvider } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { INoteRead, INoteCreate, INoteUpdate } from './noteTypes';
import { skipToken } from '@reduxjs/toolkit/query/react'
import TagSelect from '@features/tags/TagSelect';
import Quill from './Quill';

const DEFAULT_CREATE_VALUES: INoteCreate = {
    title: "",
    body: "",
    body_html: "",
    tag_ids: [],
}

const cleanReadDataForUpdate = (note: INoteRead): INoteUpdate => {
    const { title, body, body_html, tags } = note;
    return { tag_ids: tags.map(tag => tag.id), title, body, body_html };
}

interface INoteFormDialogProps {
    noteId?: INoteRead["id"];
    render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing notes.
 * It can be opened in two modes:
 * 1. Create mode: noteId is not given
 * 2. Edit mode: noteId is given
 *
 * In edit mode, the note data is fetched from the API so
 * that the form can be pre-filled with the existing note data.
 */
const NoteFormDialog: React.FC<INoteFormDialogProps> = ({ noteId, render }) => {
    const [open, setOpen] = useState(false);

    const createMode: boolean = !noteId;

    const form = useForm<INoteCreate | INoteUpdate>();

    // Delay data fetching until noteId is defined and dialog is opened
    const { data: note, isLoading: isDataLoading } = useGetNoteQuery(noteId ?? skipToken, { skip: !open });

    const [triggerCreate, {
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        isError: isCreateError

    }] = useCreateNoteMutation();
    const [triggerUpdate, {
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError
    }] = useUpdateNoteMutation();

    const isLoading = (isCreateLoading || isUpdateLoading || isDataLoading);

    React.useEffect(() => {
        if (note) {
            form.reset(cleanReadDataForUpdate(note));
        } else {
            form.reset(DEFAULT_CREATE_VALUES);
        }
    }, [note, form.reset]);

    const handleClose = () => {
        setOpen(false);
    }

    const sendData = (data: INoteCreate | INoteUpdate) => {
        if (createMode) {
            triggerCreate(data);
        } else {
            triggerUpdate({
                id: noteId || "", ...data
            });
        }
    };

    // Handle error and success notifications
    useEffect(() => {
        if (isCreateError) {
            enqueueSnackbar("Errore creazione", { variant: "error" });
        }
        if (isCreateSuccess) {
            enqueueSnackbar("Creato con successo", { variant: "success" });
            handleClose();
        }
        if (isUpdateError) {
            enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
        }
        if (isUpdateSuccess) {
            enqueueSnackbar("Aggiornato con successo", { variant: "success" });
            handleClose();
        }
    }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

    const openButton = render ? render({ onClick: () => setOpen(true) }) : <IconButton onClick={() => setOpen(true)}><EditOutlinedIcon /></IconButton>;

    return (
        <>
            {openButton}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>{createMode ? "Crea nuova nota" : "Modifica nota"}</DialogTitle>
                {!isDataLoading ? (
                    <form onSubmit={form.handleSubmit(sendData)}>
                        <DialogContent>
                            <Stack gap={2}>
                                <Controller
                                    name="title"
                                    rules={{ required: true, minLength: 3 }}
                                    control={form.control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            autoFocus
                                            label={"Titolo"}
                                            type="text"
                                            fullWidth
                                            required
                                            error={!!fieldState.error}
                                            helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="tag_ids"
                                    control={form.control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <TagSelect {...field} />
                                    )}
                                />
                                <FormProvider {...form}>
                                    <Quill htmlFieldName='body_html' textFieldName='body' />
                                </FormProvider>
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ m: 2 }}>
                            <Button
                                startIcon={<CloseOutlinedIcon />}
                                onClick={handleClose}>Annulla</Button>
                            <LoadingButton
                                startIcon={<CheckOutlinedIcon />}
                                loading={isLoading}
                                disabled={isLoading}
                                variant='contained' type="submit">{createMode ? "Crea" : "Aggiorna"}</LoadingButton>
                        </DialogActions>
                    </form>
                ) : (
                    <DialogContent>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}


export default NoteFormDialog;