import React, { useState } from "react";
import {
  useGetFilesQuery,
  useUploadFileMutation,
  useDeleteFileMutation,
} from "../../app/services/appApi";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
} from "@mui/material";
import {
  Folder as FolderIcon,
  InsertDriveFile as FileIcon,
  ArrowUpward as UploadIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { withAuth } from "../../core/auth/authWrappers";
import { IStorageFileRead } from "./storageFileTypes";
import { withAppLayout } from "../../AppLayout";
import { Page } from "@core/layout/page";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FileUploadDialog from "./FileUploadDialog";
import EditFileFormDialog from "./EditFileFormDialog";
import { idID } from "@mui/material/locale";

const FileManagerPage: React.FC = () => {
  const { t } = useTranslation();
  const [currentPath, setCurrentPath] = useState("");
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const { data: files, isLoading, refetch } = useGetFilesQuery({ path: currentPath }, { pollingInterval: 3000 });
  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useDeleteFileMutation();

  const handleUpload = async (file: File) => {
    try {
      await uploadFile({ path: `${currentPath}/${file.name}`, file }).unwrap();
      enqueueSnackbar(t("File uploaded successfully"), { variant: "success" });
      refetch();
    } catch (error) {
      enqueueSnackbar(t("File upload failed"), { variant: "error" });
    }
  };

  const handleDelete = async (id: IStorageFileRead["id"]) => {
    try {
      await deleteFile({ id }).unwrap();
      enqueueSnackbar(t("File deleted successfully"), { variant: "success" });
      refetch();
    } catch (error) {
      enqueueSnackbar(t("File deletion failed"), { variant: "error" });
    }
  };

  const handleDownload = async (id: IStorageFileRead["id"]) => {
    const selectedFile = files?.find((file) => file.id === id);
    if (selectedFile) {
      try {
        let path = selectedFile.public_presigned_url;
        if (!path.startsWith('http://') && !path.startsWith('https://')) {
          path = `/api/v1${path}`;
        }
        window.open(path, "_blank");
      } catch (error) {
        enqueueSnackbar(t("File download failed"), { variant: "error" });
      }
    }
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }


  const actions = (
    <>
      <Button
        variant="contained"
        startIcon={<UploadIcon />}
        onClick={() => setIsUploadDialogOpen(true)}
        sx={{ mr: 1 }}
      >
        {t("Upload File")}
      </Button>
    </>
  );

  const getIndexStatusColor = (status: IStorageFileRead["index_status"]) => {
    switch (status) {
      case "QUEUED":
        return "info.main";
      case "PENDING":
        return "warning.main";
      case "COMPLETED":
        return "success.main";
      case "FAILED":
        return "error.main";
      default:
        return "text.primary";
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Page title={t("File Manager")} actions={actions}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Name")}</TableCell>
                <TableCell align="right">{t("Size")}</TableCell>
                <TableCell align="right">{t("Type")}</TableCell>
                <TableCell align="right">{t("Tags")}</TableCell>
                <TableCell align="right">{t("Date Modified")}</TableCell>
                <TableCell align="right">{t("Index Status")}</TableCell>
                <TableCell align="right">{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPath && (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Button startIcon={<FolderIcon />} onClick={() => setCurrentPath(currentPath.split("/").slice(0, -1).join("/"))}>
                      ..
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7}>{t("Loading...")}</TableCell>
                </TableRow>
              ) : (
                files?.map((file) => (
                  <TableRow key={file.basename} hover>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FileIcon sx={{ mr: 1 }} />
                        {file.basename}
                      </Box>
                    </TableCell>
                    <TableCell align="right">{formatFileSize(file.size)}</TableCell>
                    <TableCell align="right">{file.is_dir ? t("Folder") : file.extension}</TableCell>
                    <TableCell align="right">{file.tags.map((tag) => tag.name).join(", ")}</TableCell>
                    <TableCell align="right">{new Date(file.updated_at).toLocaleString()}</TableCell>
                    <TableCell align="right" sx={{ color: getIndexStatusColor(file.index_status) }}>
                      {t(file.index_status)}
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                        <IconButton onClick={() => handleDownload(file.id)} disabled={file.is_dir}>
                          <DownloadIcon />
                        </IconButton>
                        <EditFileFormDialog fileId={file.id} />
                        <IconButton onClick={() => handleDelete(file.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <FileUploadDialog
          open={isUploadDialogOpen}
          onClose={() => setIsUploadDialogOpen(false)}
          onUpload={handleUpload}
        />

      </Page>
    </DndProvider>
  );
};

export default withAppLayout(withAuth(FileManagerPage));