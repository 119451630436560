import React from "react";
import { withAuth } from "../../core/auth/authWrappers";
import { useTranslation } from "react-i18next";
import LanguageSelect from "./LanguageSelect";
import { Stack, Typography, Paper, Container, Box } from "@mui/material";
import { withAppLayout } from "../../AppLayout";
import { Page } from "@core/layout/page";
import TranslateIcon from '@mui/icons-material/Translate';

const ProfilePage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Page title={t("Settings")}>
            <Container maxWidth="sm">
                <Paper elevation={0} sx={{ p: 4, mt: 4 }}>
                    <Stack spacing={3}>
                        <Box display="flex" alignItems="center">
                            <TranslateIcon sx={{ mr: 1 }} />
                            <Typography variant="h5" component="h2" gutterBottom>
                                {t("Language Settings")}
                            </Typography>
                        </Box>
                        <Typography variant="body1" gutterBottom>
                            {t("Select your preferred language for the application.")}
                        </Typography>
                        <LanguageSelect />
                    </Stack>
                </Paper>
            </Container>
        </Page>
    );
};

export default withAppLayout(withAuth(ProfilePage));
