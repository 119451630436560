import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import ViewerIconButton from './viewerIconButton'

interface ViewerToolbarProps {
    onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

/**
 * Top-right viewer toolbar.
 * Right now it only contains a close button.
 */
export function ViewerToolbar({
    onClose
}: ViewerToolbarProps): JSX.Element {
    return (
        <Stack
            direction="row"
            gap={1}
            sx={{ position: 'absolute', top: 5, right: 5 }}
        >
            {/* Close button */}
            <ViewerIconButton onClick={onClose} size="large">
                <CloseIcon />
            </ViewerIconButton>
        </Stack>
    )
}


export default ViewerToolbar