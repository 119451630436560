import React from 'react'
import Avatar from "@mui/material/Avatar";
import { useTheme } from '@mui/material/styles';

interface AppLogoProps extends React.ComponentProps<typeof Avatar> { }

export const AppLogo: React.FC<AppLogoProps> = ({ sx, ...props }) => {
    const theme = useTheme();

    return <Avatar
        sx={{
            bgcolor: theme.app.logo.square.bgColor,
            width: theme.app.logo.square.width,
            height: theme.app.logo.square.height,
            borderRadius: theme.app.logo.square.borderRadius,
            ...sx
        }}
        src={theme.app.logo.square.imgSrc}
        alt='app logo'
        {...props}
    />
}
