import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import { useNavigate, useLocation } from "react-router-dom";
import useAuthContext, { IAuthContextProps } from "../../auth/AuthContext";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useTranslation } from "react-i18next";
import NavDrawerHeader from "./NavDrawerHeader";
import { useTheme } from '@mui/material/styles';
import { INavItem } from "./NavItem";
import NavItem from "./NavItem";
import PoweredByNavItem from "./PoweredByNavItem";

export type RenderNavItemsProps = (props: { authContext: IAuthContextProps }) => INavItem[];


export interface INavDrawerProps {
  children: React.ReactNode;
  renderNavItems: RenderNavItemsProps;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.app.nav.bgColor,
  color: theme.app.nav.color,
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.app.nav.bgColor,
  color: theme.app.nav.color,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export function NavDrawer({ children, renderNavItems }: INavDrawerProps) {
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const authContext = useAuthContext();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const visibleNavItems = renderNavItems({ authContext })
    .filter(item => item.isVisible !== undefined ? item.isVisible : true)
  const topNavItems = visibleNavItems
    .filter(item => item.position === "top" || item.position === undefined);
  const bottomNavItems = visibleNavItems
    .filter(item => item.position === "bottom");

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={open}>
        <NavDrawerHeader onClick={handleDrawerToggle} open={open} />
        <Stack direction="column" justifyContent="space-between" height="100%">
          <List>
            <List>
              {topNavItems.map(item => <NavItem item={item} open={open} key={item.url} />)}
            </List>
          </List>
          <List sx={{ borderLeft: "3px solid white" }}>
            <PoweredByNavItem open={open} />
            {bottomNavItems.map(item => <NavItem item={item} open={open} key={item.url} />)}
            <NavItem item={{
              icon: open ? <KeyboardDoubleArrowLeftIcon sx={{ color: theme.app.nav.color }} /> : <KeyboardDoubleArrowRightIcon sx={{ color: theme.app.nav.color }} />,
              text: open ? "Collapse" : "Expand",
              onClick: handleDrawerToggle,
              position: "bottom"
            }} open={open} />
          </List>
        </Stack>
      </Drawer>
      {children}
    </Box>
  );
}
