import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetFileQuery, useUpdateFileMutation } from '../../app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { IStorageFileRead, IStorageFileUpdate } from './storageFileTypes';
import { skipToken } from '@reduxjs/toolkit/query/react'
import TagSelect from '@features/tags/TagSelect';


const cleanReadDataForUpdate = (storageFile: IStorageFileRead): IStorageFileUpdate => {
    const { tags, user_fullpath } = storageFile;
    return { tag_ids: tags.map(tag => tag.id), user_fullpath };
}

interface IEditFileFormDialogProps {
    fileId?: IStorageFileRead["id"];
    render?: (props: { onClick: () => void }) => React.ReactNode;
}

const EditFileFormDialog: React.FC<IEditFileFormDialogProps> = ({ fileId, render }) => {
    const [open, setOpen] = useState(false);

    const form = useForm<IStorageFileUpdate>();


    // Delay data fetching until dialog is opened
    const { data: fileData, isLoading: isDataLoading } = useGetFileQuery(fileId ?? skipToken, { skip: !open });

    const [triggerUpdate, {
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError
    }] = useUpdateFileMutation();

    const isLoading = (isUpdateLoading || isDataLoading);

    React.useEffect(() => {
        if (fileData) {
            form.reset(cleanReadDataForUpdate(fileData));
        } else {
            form.reset();
        }
    }, [fileData, form.reset]);

    const handleClose = () => {
        setOpen(false);
    }

    const sendData = (data: IStorageFileUpdate) => {
        triggerUpdate({ id: fileId || -1, ...data });
    }

    // Handle error and success notifications
    useEffect(() => {
        if (isUpdateError) {
            enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
        }
        if (isUpdateSuccess) {
            enqueueSnackbar("Aggiornato con successo", { variant: "success" });
            handleClose();
        }
    }, [isUpdateError, isUpdateSuccess]);

    const openButton = render ? render({ onClick: () => setOpen(true) }) : <IconButton onClick={() => setOpen(true)}><EditOutlinedIcon /></IconButton>;

    return (
        <>
            {openButton}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Modifica file</DialogTitle>
                {!isDataLoading ? (
                    <form onSubmit={form.handleSubmit(sendData)}>
                        <DialogContent>
                            <Stack gap={2}>
                                <Controller
                                    name="user_fullpath"
                                    rules={{ required: true, minLength: 3 }}
                                    control={form.control}
                                    defaultValue=""
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            autoFocus
                                            label={"Path"}
                                            type="text"
                                            fullWidth
                                            required
                                            error={!!fieldState.error}
                                            helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="tag_ids"
                                    control={form.control}
                                    defaultValue={[]}
                                    render={({ field }) => (
                                        <TagSelect {...field} />
                                    )}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ m: 2 }}>
                            <Button
                                startIcon={<CloseOutlinedIcon />}
                                onClick={handleClose}>Annulla</Button>
                            <LoadingButton
                                startIcon={<CheckOutlinedIcon />}
                                loading={isLoading}
                                disabled={isLoading}
                                variant='contained' type="submit">Aggiorna</LoadingButton>
                        </DialogActions>
                    </form>
                ) : (
                    <DialogContent>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}



export default EditFileFormDialog;