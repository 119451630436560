import { ITopKResult } from '../../app/services/apiTypes';
import { useGetNoteQuery } from '../../app/services/appApi';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export interface INoteViewerProps {
    resource: ITopKResult;
}

export default function NoteViewer({ resource }: INoteViewerProps) {
    const { data: note, isLoading, isError } = useGetNoteQuery(resource.src_ref_id);

    const style = {
        bgcolor: 'background.paper',
        p: 4,
        borderRadius: 1,
        // height: '100%',
        // overflowY: 'auto',
    };

    return (
        <Box sx={style}>
            {isError &&
                <Typography variant="body1" color="error">An error occurred while fetching the note</Typography>
            }
            {isLoading &&
                <CircularProgress />
            }
            {note && (
                <Stack direction="column" gap={2} >
                    <Typography variant="h5">{note.title}</Typography>
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: note.body_html }}></Typography>
                </Stack>
            )}
        </Box>
    );
}