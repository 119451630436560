import React from 'react';
import { Typography, Stack } from '@mui/material';

interface PageProps {
    title: string;
    actions?: React.ReactNode;
    children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ title, actions, children }) => {
    return (
        <>
            <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="h5" component="h1" noWrap sx={{ flexGrow: 1 }}>
                    <b>{title}</b>
                </Typography>
                {actions}
            </Stack>
            {children}
        </>
    );
};

export { Page };