import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";
import { t } from 'i18next';
import { largeBotAvatar } from "./ChatAvatars";
import { IChatHistoryProps } from "./ChatHistory";
import DeviceSelect from "@features/device/DeviceSelect";



function EmptyHistoryPlaceholder({ onStartChat, loading }: Pick<IChatHistoryProps, "onStartChat" | "loading">) {


    return (
        <Stack alignItems="center" sx={{ textAlign: "center", p: 4 }}>
            {largeBotAvatar}
            <Typography variant="h6" sx={{ mt: 2 }}>
                <b>{t('AI Assistant')}</b>
            </Typography>
            <Typography variant="body1">
                {t('The AI assistant can help you solve the problem')}
            </Typography>
            <DeviceSelect />
            {/* <Btn
                variant="contained"
                sx={{ m: 2 }}
                onClick={onStartChat}
                loading={loading}
                fakeDelayMs={1000}
            >
                {t('Get help')}
            </Btn> */}

        </Stack>
    );
}

export default EmptyHistoryPlaceholder;