import React, { useState, useEffect } from 'react';
import Fab from '@mui/material/Fab';
import ChatIcon from '@mui/icons-material/Chat';
import { useTheme } from '@mui/material/styles';
import AssistantDrawer from './AssistantDrawer';
import { useLocation, useNavigate } from "react-router-dom";

const AssistantFloatingBtn: React.FC = () => {
    const theme = useTheme();
    const location = useLocation();
    const [openDrawer, setOpenDrawer] = useState(false);

    // Open the drawer if the ?startchat= query parameter is present. This is needed to launch the assistant from a link
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.has('startchat')) {
            setOpenDrawer(true);
        }
    }, [location.search]);

    return (
        <>
            <Fab color="primary" aria-label="show assistant" onClick={() => setOpenDrawer(true)}
                sx={{
                    position: 'fixed',
                    bottom: theme.spacing(2),
                    right: theme.spacing(2),

                }}>
                <ChatIcon />
            </Fab >
            <AssistantDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} onMessageSubmit={() => setOpenDrawer(true)} />
        </>
    );
};

export default AssistantFloatingBtn;
