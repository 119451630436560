import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { withAuth } from "../../core/auth/authWrappers";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import useAuthContext from "../../core/auth/AuthContext";
import { useTranslation } from "react-i18next";
import { withAppLayout } from "../../AppLayout";
import { Page } from "@core/layout/page";
import TagIcon from '@mui/icons-material/Tag';

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuthContext();

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('it-IT', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  };

  return (
    <Page title={t("User Profile")}>
      <Paper elevation={0} sx={{ p: 4, mt: 4, maxWidth: 600, mx: "auto" }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 4 }}>
          <AccountCircleOutlinedIcon sx={{ fontSize: 80 }} />
          <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
            {user?.username}
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <BusinessIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body1">
                <strong>{t("Organization", { count: 1 })}:</strong> {user?.organization.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SupervisorAccountIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body1">
                <strong>{t("Role")}:</strong> {user?.role}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TagIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body1">
                <strong>{t("Organization ID")}:</strong> {user?.organization.id}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CalendarTodayIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body1">
                <strong>{t("Created At")}:</strong> {user?.created_at && formatDate(user?.created_at)}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button variant="contained" color="primary" onClick={logout} sx={{ minWidth: 120 }}>
            {t("Logout")}
          </Button>
        </Box>
      </Paper>
    </Page>
  );
};

export default withAppLayout(withAuth(ProfilePage));
