import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import { IChatMessage } from "../../../app/services/apiTypes";
import ReactMarkdown from 'react-markdown';
import { botAvatar, userAvatar } from "./ChatAvatars";


const ChatMessage: React.FC<{ chatMessage: IChatMessage; alignRight?: boolean }> = ({ chatMessage, alignRight }) => (
  <ListItem alignItems="flex-start" sx={{
    px: 0,
    py: 0.75
  }}>
    {!alignRight && <ListItemAvatar>{userAvatar}</ListItemAvatar>}
    <ListItemText
      sx={{
        bgcolor: "background.paper",
        boxShadow: "0px 2px 25px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        position: "relative",
        padding: "16px 24px",
        "&::before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: "30px",
          [alignRight ? "right" : "left"]: "-10px",
          width: "0",
          height: "0",
          borderTop: "10px solid transparent",
          borderBottom: "10px solid transparent",
          [alignRight ? "borderLeft" : "borderRight"]: "10px solid #fff",
        },
      }}
      primary={
        <>

          <Typography
            component="span"
            variant="body2"
            color="text.primary"
          // sx={{
          //   whiteSpace: "pre-line"
          // }}
          >
            <ReactMarkdown>{chatMessage.body || "..."}</ReactMarkdown>
          </Typography>
          {/* <Button
            sx={{ my: 1 }}
            onClick={() => { }}
            fullWidth
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<DescriptionIcon />}
          >
            Apri documento
          </Button> */}
        </>
      }
    />

    {alignRight && <ListItemAvatar sx={{ ml: 2 }}>{botAvatar}</ListItemAvatar>}
  </ListItem>
);


export { ChatMessage };