import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useGetTagsQuery } from "@app/services/appApi";
import { ITagRead } from './tagTypes';

interface TagSelectOptions {
    value: string[]; // Tag IDs
    onChange: (value: string[]) => void; // Tag IDs
}

const TagSelect = React.forwardRef<HTMLDivElement, TagSelectOptions>(({ value, onChange }, ref) => {
    const { t } = useTranslation();
    const { data: options = [] } = useGetTagsQuery();


    // Convert incoming tag IDs to tag objects
    const selectedTags = options.filter(tag => value.includes(tag.id));

    const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: (ITagRead | string)[]) => {
        const newTagIds = newValue.map((item) => {
            if (typeof item === 'string') {
                // Handle free solo input
                const matchedTag = options.find(tag => tag.name.toLowerCase() === item.toLowerCase());
                return matchedTag ? matchedTag.id : item; // Return ID if matched, otherwise return the string
            }
            return item.id;
        });
        onChange(newTagIds);
    }

    return (
        <Autocomplete
            value={selectedTags || []}
            onChange={handleChange}
            defaultValue={[]}
            multiple
            disableCloseOnSelect
            options={options}
            getOptionLabel={(option) => {
                if (typeof option === 'string') return option;
                return option.name;
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            freeSolo
            renderTags={(value: readonly (ITagRead | string)[], getTagProps) =>
                value.map((option: ITagRead | string, index: number) => (
                    <Chip
                        color="info"
                        label={typeof option === 'string' ? option : option.name}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("Tag", { count: 2 })}
                    placeholder={t("Type and press Enter to add tags")}
                    inputRef={ref}
                />
            )}
        />
    );
})

export default TagSelect;
