import Drawer from "@mui/material/Drawer";
import { ILogRecord } from "../../app/services/apiTypes";
import { Box, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/system/Stack";
import LogRecordDetail from "./LogRecordDetail";
import IconButton from "@mui/material/IconButton";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import CheckIcon from "@mui/icons-material/Check";
import ChatColumn from "./chat/ChatColumn";
import NoteLogBar from "../note/NoteLogBar";
import { useTranslation } from "react-i18next";

interface ILogDetailsDrawerProps {
  logRecord: ILogRecord | null;
  onClose: () => void;
}

export default function LogDetailsDrawer({
  logRecord,
  onClose,
}: ILogDetailsDrawerProps) {
  const { t } = useTranslation();

  return (
    <>
      <Drawer anchor="right" open={logRecord !== null} onClose={onClose}>
        <Stack direction="row">
          {/* Sidebar #1 */}
          <Stack direction="column" sx={{ width: "550px" }}>
            {/* Top buttons row */}
            <Stack direction="row" gap={2} sx={{ p: 2 }}>
              {/* Close drawer btn */}
              <IconButton size="small" onClick={onClose}>
                <KeyboardTabIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                onClick={onClose}
                sx={{ float: "right" }}
                variant="outlined"
                startIcon={<CheckIcon />}
                size="small"
              >
                {t("Resolve issue")}
              </Button>
              <Button sx={{ float: "right" }} size="small" disabled>
                {t("Edit")}
              </Button>
            </Stack>
            <Divider />
            {/* Log entry details */}
            {logRecord && <LogRecordDetail logRecord={logRecord} />}
            <Divider />
            <Stack sx={{ p: 4 }}>
              {/* <DocsPaper title="Codici di errore macchina" />
              <DocsPaper title="Configurazione iniziale" /> */}
              {logRecord && <NoteLogBar logRecord={logRecord} />}
            </Stack>
          </Stack>
          {/* Sidebar #2 */}
          {<ChatColumn />}
        </Stack>
      </Drawer>
    </>
  );
}
