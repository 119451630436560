import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { default as MuiSelect } from '@mui/material/Select'
import { ISelectOption } from './Select.types'
import { nanoid } from 'nanoid'

export interface ISelectProps<T = string | number> extends React.ComponentProps<typeof MuiSelect> {
    label: string
    options: ISelectOption<T>[]
    formControlProps?: React.ComponentProps<typeof FormControl>
}

const Select = React.forwardRef(({ label, options, formControlProps, ...props }: ISelectProps, ref) => {
    const labelId = nanoid()

    return (
        <FormControl {...formControlProps}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <MuiSelect labelId={labelId} label={label} ref={ref} {...props}>
                {/* <MenuItem value="" key="emptyval">
                    <em>Nessuna selezione</em>
                </MenuItem> */}
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </MuiSelect>
        </FormControl >
    )
})

export default Select