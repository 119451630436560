import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";

export interface INavItem {
    icon: JSX.Element;
    text: string;
    url?: string;
    onClick?: () => void;
    isVisible?: boolean;
    position?: "top" | "bottom";
}

interface NavItemProps {
    item: INavItem,
    open: boolean
}

export default function NavItem({ item, open }: NavItemProps) {
    const location = useLocation();
    const navigate = useNavigate();

    // Is active if the current location matches the url prefix
    const isActive = item.url && location.pathname.startsWith(item.url);

    const handleNavigate = (url: string) => {
        // If the url is an external link, open it in a new tab
        if (url.startsWith("http")) {
            window.open(url, "_blank");
        } else {
            navigate(url);
        }
    };

    return (
        <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
            <ListItemButton
                onClick={item.onClick || !item.url ? item.onClick : () => handleNavigate(item.url || "/")}
                sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    fontWeight: isActive ? "bold" : "normal",
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                    }}
                >
                    {item.icon}
                </ListItemIcon>
                <ListItemText
                    primary={item.text}
                    sx={{
                        opacity: open ? 1 : 0,
                    }}
                />
            </ListItemButton>
        </ListItem>
    );
};
