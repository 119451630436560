import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGetUserQuery, useCreateUserMutation, useUpdateUserMutation } from '@app/services/appApi';
import { enqueueSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { IUserRead, IUserCreate, IUserUpdate } from '@core/auth/types';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query/react'

const DEFAULT_CREATE_VALUES: IUserCreate = {
    username: "",
    organization_id: "",
    password: "",
    role: "USER"
}

const cleanReadUserDataForUpdate = (user: IUserRead): IUserUpdate => {
    const { id, created_at, updated_at, organization, ...rest } = user;
    return { password: "", organization_id: organization.id, ...rest };
}

interface IUserFormDialogProps {
    userId?: IUserRead["id"];
    render?: (props: { onClick: () => void }) => React.ReactNode;
}

/**
 * Dialog component for creating and editing users.
 * It can be opened in two modes:
 * 1. Create mode: userId is not given
 * 2. Edit mode: userId is given
 *
 * In edit mode, the user data is fetched from the API so
 * that the form can be pre-filled with the existing user data.
 */
const UserFormDialog: React.FC<IUserFormDialogProps> = ({ userId, render }) => {
    const [open, setOpen] = useState(false);

    const createMode: boolean = !userId;

    const form = useForm<IUserCreate | IUserUpdate>();

    // Delay data fetching until userId is defined and dialog is opened
    const { data: user, isLoading: isDataLoading } = useGetUserQuery(userId ?? skipToken, { skip: !open });

    const [triggerCreate, {
        isLoading: isCreateLoading,
        isSuccess: isCreateSuccess,
        isError: isCreateError

    }] = useCreateUserMutation();
    const [triggerUpdate, {
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError
    }] = useUpdateUserMutation();

    const isLoading = (isCreateLoading || isUpdateLoading || isDataLoading);

    React.useEffect(() => {
        if (user) {
            form.reset(cleanReadUserDataForUpdate(user));
        } else {
            form.reset(DEFAULT_CREATE_VALUES);
        }
    }, [user, form.reset]);

    const handleClose = () => {
        setOpen(false);
    }

    const sendData = (data: IUserCreate | IUserUpdate) => {
        if (createMode) {
            triggerCreate(data as IUserCreate);
        } else {
            triggerUpdate({
                id: userId || -1, ...data
            });
        }
    };

    // Handle error and success notifications
    useEffect(() => {
        if (isCreateError) {
            enqueueSnackbar("Errore creazione", { variant: "error" });
        }
        if (isCreateSuccess) {
            enqueueSnackbar("Creato con successo", { variant: "success" });
            handleClose();
        }
        if (isUpdateError) {
            enqueueSnackbar("Impossibile aggiornare", { variant: "error" });
        }
        if (isUpdateSuccess) {
            enqueueSnackbar("Aggiornato con successo", { variant: "success" });
            handleClose();
        }
    }, [isCreateError, isCreateSuccess, isUpdateError, isUpdateSuccess]);

    const openButton = render ? render({ onClick: () => setOpen(true) }) : <IconButton onClick={() => setOpen(true)}><EditOutlinedIcon /></IconButton>;

    return (
        <>
            {openButton}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>{createMode ? "Crea nuovo utente" : "Modifica utente"}</DialogTitle>
                {!isDataLoading ? (
                    <form onSubmit={form.handleSubmit(sendData)}>
                        <DialogContent>
                            <Stack gap={2}>
                                <Controller
                                    name="username"
                                    rules={{ required: true, minLength: 3 }}
                                    control={form.control}
                                    defaultValue=""
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            autoFocus
                                            label={"Username"}
                                            type="text"
                                            fullWidth
                                            required
                                            error={!!fieldState.error}
                                            helperText={!!fieldState.error && "Il campo deve contenere almeno 3 caratteri"}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="organization_id"
                                    rules={{ required: true }}
                                    control={form.control}
                                    defaultValue=""
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            label={"ID Organizzazione"}
                                            type="text"
                                            fullWidth
                                            required
                                            error={!!fieldState.error}
                                            helperText={!!fieldState.error && "Il campo è obbligatorio"}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="password"
                                    control={form.control}
                                    rules={{ required: createMode }}
                                    defaultValue=""
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            label={"Password"}
                                            type="password"
                                            fullWidth
                                            required={createMode}
                                            error={!!fieldState.error}
                                            helperText={!createMode ? "Lascia vuoto per non cambiare" : !!fieldState.error && "Il campo è obbligatorio"}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="role"
                                    rules={{ required: createMode }}
                                    defaultValue="USER"
                                    control={form.control}
                                    render={({ field, fieldState }) => (
                                        <FormControl fullWidth>
                                            <InputLabel id="role-select">Ruolo</InputLabel>
                                            <Select
                                                labelId="role-select"
                                                label="Ruolo"
                                                {...field}
                                            >
                                                <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                                                <MenuItem value={"USER"}>USER</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ m: 2 }}>
                            <Button
                                startIcon={<CloseOutlinedIcon />}
                                onClick={handleClose}>Annulla</Button>
                            <LoadingButton
                                startIcon={<CheckOutlinedIcon />}
                                loading={isLoading}
                                disabled={isLoading}
                                variant='contained' type="submit">{createMode ? "Crea" : "Aggiorna"}</LoadingButton>
                        </DialogActions>
                    </form>
                ) : (
                    <DialogContent>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    </DialogContent>
                )}
            </Dialog>
        </>
    );
}


export default UserFormDialog;