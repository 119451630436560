import { useState } from "react";
import { useGetLogRecordsQuery } from "@app/services/appApi";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import LogDetailsDrawer from "../assistant/LogDetailsDrawer";
import { ILogRecord } from "@app/services/apiTypes";
import LogLevelChip from "./LogLevelChip";
import { withAuth } from "@core/auth/authWrappers";
import { useAssistantContext } from "../assistant/useAssistantContext";
import { useTranslation } from "react-i18next";
import { withAppLayout } from "../../AppLayout";
import { Page } from "@core/layout/page";
import DeviceSelect from "@features/device/DeviceSelect";

const columns: GridColDef[] = [
  {
    field: "level",
    headerName: "Level", //TODO: translate
    width: 130,
    //@ts-ignore-next-line
    renderCell: (params: GridValueGetterParams) => (
      <LogLevelChip
        level={params.value as ILogRecord["level"]}
        variant="outlined"
      />
    ),
  },
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "created_at",
    headerName: "Timestamp",//TODO: translate
    width: 180,
  },
  {
    field: "body",
    headerName: "Message",//TODO: translate
    // expand row to fill remaining space
    flex: 1,
  },
];

function LogViewer() {
  const { t } = useTranslation();
  const { setRelevLogRecordId,
    relevDeviceId, setRelevDeviceId
  } = useAssistantContext();
  const { data } = useGetLogRecordsQuery({ deviceId: relevDeviceId || "" }, { skip: !relevDeviceId, pollingInterval: 3000 });

  const [selectedLogRecord, setSelectedLogRecord] = useState<ILogRecord | null>(null);


  const handleLogRecordSelect = (logRecord: ILogRecord | null) => {
    setSelectedLogRecord(logRecord);
    // update the relevantLogRecord in the assistant context
    setRelevLogRecordId(logRecord ? logRecord.id : undefined);
  }

  const actions = (
    <DeviceSelect />
  )

  return (
    <Page title={t('Log Viewer')} actions={actions}>
      <DataGrid
        rows={data || []}
        columns={columns}
        onRowClick={(params) => handleLogRecordSelect(params.row)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20, 50, 100]}
        sx={{ height: "500px", mt: 2 }}
      />
      <LogDetailsDrawer
        logRecord={selectedLogRecord}
        onClose={() => handleLogRecordSelect(null)}
      />
    </Page>
  );
}

export default withAppLayout(withAuth(LogViewer));
